import React, { useEffect, useRef, useState, useCallback } from "react";
import QuestionAnswer from "./QuestionAnswer";
import {
  createSurvey,
  getQuestions,
  updateAnswer,
  getLanguages,
  getFollowupQuestion,
  getSurveyStatments,
  getInitialQuestion,
  getAllFailedApiResponses,
} from "../../services/apiServices";
import sendIcon from "../../assets/send-svgrepo-com (2).svg";
import "./QuestionsList.css";
import ProgressBar from "./ProgressBar";
import "normalize.css";
import ISO6391 from "iso-639-1";
import { emojiesValues } from "../../config/constants";
import OfflineMessage from "../Offline";
import Dropdown from "./Dropdown";

const LanguageSelection = [{ question: "Select Language" }];

export default function QuestionListV2() {
  const [intialQuestion, setIntialQuestion] = useState();
  const [delayedQuestions, setDelayedQuestions] = useState([]);
  const [showMessageList, setShowMessagesList] = useState([]);
  const [intialQuestions, setIntialQuestions] = useState([]);
  const [isSurveyStart, setIsSurveyStart] = useState(false);
  const [initialQIndex, setInitialQIndex] = useState(0);
  const [textValue, setTextValue] = useState("");
  const [messagesList, setMessagesList] = useState([]);
  const [index, setIndex] = useState(0);
  const textareaRef = useRef(null);
  const [editIsOn, setEditIsOn] = useState(false);
  const [shouldWeStart, setShouldWeStart] = useState(false);
  const [startSurvey, setStartSurvey] = useState({
    isSurveyStart: false,
    comment: "",
  });
  const [editedQuestionId, setEditedQuestionId] = useState(0);
  const [userInput, setUserInput] = useState(null);
  const [isResponseSubmitted, setIsResponseSubmitted] = useState(false);
  const [isLinkInValid, setIsInLinkValid] = useState(false);
  const [avlLangauges, setAvlLangauges] = useState([]);
  const [followupQuestions, setFollowupQuestions] = useState([]);
  const [selectedLangauge, setSelectedLanguage] = useState({});
  const [staticStatments, setStaticStatment] = useState({});
  const [selectOptionQuestion, setSelectOptionQuestion] = useState({
    question: "Please select the category to which your comment belongs:",
  });
  const [invalidLinkMessage, setInvalidLinkMessage] = useState(
    "Apologies for the inconvenience. The provided link is not valid. If you need any additional assistance or support, please reach out to your HR department."
  );
  const [customLTypeQuestion, setCustomLTypeQuestion] = useState();
  const [textAreaRows, setTextAreaRows] = useState(2);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [total, setTotal] = useState(0);
  const [isSurvyeSubmitted, setisSurvyeSubmitted] = useState(false);
  const [languagesCallbackCalled, setLanguagesCallbackCalled] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const handleTextareaInput = (event) => {
    const inputValue = event.target.value;
    // Count the occurrences of new lines (\n) in the input value
    const newLineCount = (inputValue.match(/\n/g) || []).length;
    // Set the minimum number of rows (e.g., 1) and the maximum number of rows (e.g., 5) based on your design.
    const minRows = 2;
    const maxRows = 5;
    setTextValue(event.target.value);
    // Calculate the number of rows based on the new line count, and keep it within the min and max rows
    const calculatedRows = Math.min(
      Math.max(minRows, newLineCount + 1),

      maxRows
    );
    setTextAreaRows(calculatedRows);
  };

  const getLanguagesCallBack = useCallback(async (surveyId, token) => {
    await createSurvey(token, surveyId)
      .catch((err) => {
        console.log(err);
        if (err?.response?.data) {
          if (err?.response?.data?.message === "Survey is expired") {
            setInvalidLinkMessage(
              statment?.[selectedLangauge.answer_code]?.surveyExpired
            );
            return;
          }
        }
        //   setIsInLinkValid(true);
      })
      .then(async (data) => {
        if (data) {
          let { questions } = data;
          if (questions?.length > 0) {
            if (questions[0].submitdate) {
              const { statment } = await getSurveyStatments(
                surveyId,
                questions[0].startlanguage
              );
              setStaticStatment(statment);

              setIsResponseSubmitted(true);
              return;
            }
          }
        }
      });
    const { languages } = await getLanguages(surveyId);
    const { language, additional_languages, bouncetime, bounceaccounttype } =
      languages[0];
    console.log(additional_languages, "additional_languages");
    if (bouncetime && Number(bouncetime) === 1) {
      setCustomLTypeQuestion(true);
    }
    const langaugesAvailbaleString = additional_languages;
    let langaugesAvailbale = [];
    if (langaugesAvailbaleString && langaugesAvailbaleString !== "") {
      langaugesAvailbale = langaugesAvailbaleString.split(" ");
    }
    langaugesAvailbale.push(language);
    langaugesAvailbale = langaugesAvailbale.map((lang) => {
      return {
        answer_code: lang,
        answer: ISO6391.getNativeName(lang),
      };
    });
    const uniqueLanguages = langaugesAvailbale.reduce((acc, current) => {
      const x = acc.find((item) => item.answer_code === current.answer_code);
      if (!x) {
        acc.push(current);
      }
      return acc;
    }, []);

    LanguageSelection[0].answers = uniqueLanguages;
    setAvlLangauges(uniqueLanguages);
    if (uniqueLanguages.length === 1) {
      setSelectedLanguage(langaugesAvailbale[0]);
    }
  }, []);

  const getQuestionList = useCallback(
    async (token, surveyId, langauge) => {
      await createSurvey(token, surveyId, langauge)
        .catch((err) => {
          console.log(err);
          if (err?.response?.data) {
            if (err?.response?.data?.message === "Survey is expired") {
              setInvalidLinkMessage(
                statment?.[selectedLangauge.answer_code]?.surveyExpired
              );
            }
          }
          setIsInLinkValid(true);
        })
        .then((data) => {
          console.log(">>>>>>>>>>>>", data);
        });
      const { languages } = await getLanguages(surveyId);
      const { bouncetime, bounceaccounttype } = languages[0];

      const added_questions = [];
      const { followup_question } = await getFollowupQuestion(
        langauge,
        surveyId
      );
      setFollowupQuestions(followup_question);
      const { statment } = await getSurveyStatments(surveyId, langauge);
      setStaticStatment(statment);
      setInvalidLinkMessage(staticStatments.InvalidLink);
      setSelectOptionQuestion({
        question: statment?.[langauge].otypeOptionSelection,
      });
      const queryParams = new URLSearchParams(window.location.search);
      let name = queryParams.get("name") || "Name";
      const relation = queryParams.get("relation") || "Relation";
      let { initial_question } = await getInitialQuestion(
        langauge,
        surveyId,
        relation
      );
      // Update the initial_question array
      let mainLanguage = languages[0].language;
      let additional_lang = languages[0].additional_languages.split(" ");
      const updated_initial_question = {};
      for (let lang of [mainLanguage, ...additional_lang]) {
        console.log(
          ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> LANG <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<,",
          lang
        );
        updated_initial_question[lang] = initial_question[lang].map(
          (quest, index) => {
            const updatedQuestion = quest.question
              .replace(/\${name}/g, name)
              .replace(/\${relation}/g, relation);
            return {
              ...quest,
              qid: index,
              question: updatedQuestion,
            };
          }
        );
      }

      setIntialQuestion(updated_initial_question);

      let { questions } = await getQuestions(
        token,
        surveyId,
        langauge,
        relation
      ).catch(() => {
        setIsInLinkValid(true);
      });
      questions = questions[langauge].map((quest) => {
        const updatedQuestion = quest.question
          .replace(/\${name}/g, name)
          .replace(/\${relation}/g, relation);
        return {
          ...quest,
          question: updatedQuestion,
        };
      });
      setTotal(questions.length);
      if (questions.length === 1) {
        setIsResponseSubmitted(true);
        return;
      }

      for (let question of questions) {
        if (question?.type === "L") {
          if (Number(bouncetime) === 1 && question.givenAnswer.answer_code) {
            const givenAnswer = question.answer.find((ans) => {
              return question.givenAnswer.answer_code === ans.answer_code;
            });
            question.givenAnswer.answer = givenAnswer.answer;
          }
          added_questions.push(question);
        }
        if (question?.type === "O") {
          let newQuestionObj = { ...question };
          delete newQuestionObj.answer;

          // added_questions.push(question)
          if (Number(bounceaccounttype) === 1) {
            newQuestionObj.qid = newQuestionObj.qid + "comment";
            newQuestionObj.type = "comment";
            newQuestionObj.givenAnswer = {
              comment: newQuestionObj.givenAnswer.comment,
            };
            // added_questions.push(newQuestionObj);
            // question.question = statment?.otypeOptionSelection;
            question.givenAnswer.comment = null;
            added_questions.push(question);
            delete newQuestionObj.question;
            added_questions.push(newQuestionObj);
          } else {
            newQuestionObj.qid = newQuestionObj.qid + "comment";
            newQuestionObj.type = "comment";
            newQuestionObj.givenAnswer = {
              comment: newQuestionObj.givenAnswer.comment,
            };
            // added_questions.push(newQuestionObj);
            question.question = statment?.[langauge]?.otypeOptionSelection;
            question.givenAnswer.comment = null;
            added_questions.push(newQuestionObj);
            added_questions.push(question);
          }
        }
        if (question?.type === "T") {
          delete question.answer;
          if (
            question.title.startsWith(
              added_questions[added_questions.length - 1].title
            )
          ) {
            if (
              added_questions[added_questions.length - 1].givenAnswer
                ?.answer_code <= 3
            ) {
              added_questions.push({
                ...question,
                comment: question?.givenAnswer?.comment,
              });
            }
          } else {
            added_questions.push({
              ...question,
              comment: question?.givenAnswer?.comment,
            });
          }
        }
      }
      added_questions.push({
        question: statment?.[langauge]?.thanksResponse,
        type: "O",
        qid: 1,
      });
      let showQuestionList = added_questions.filter((quest) => {
        return (
          (quest?.givenAnswer &&
            quest?.givenAnswer?.answer_code &&
            quest?.givenAnswer?.answer_code !== null) ||
          (quest?.givenAnswer &&
            quest?.givenAnswer?.comment &&
            quest?.givenAnswer?.comment !== null)
        );
      });

      let questionList = added_questions.filter((quest) => {
        return !(
          (quest?.givenAnswer &&
            quest?.givenAnswer?.answer_code &&
            quest?.givenAnswer?.answer_code !== null) ||
          (quest?.givenAnswer &&
            quest?.givenAnswer?.comment &&
            quest?.givenAnswer?.comment !== null)
        );
      });

      setMessagesList(questionList);
      if (showQuestionList.length === 0) {
        setShowMessagesList([questionList.shift()]);
      } else {
        setShowMessagesList([...showQuestionList, questionList.shift()]);
      }
    },
    [setMessagesList, setShowMessagesList]
  );

  const onLanguageSelection = (lang) => {
    setSelectedLanguage(lang);
    LanguageSelection[0].givenAnswer = {
      answer: lang.answer_code,
      comment: lang.answer,
    };
  };
  let callback = false;
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const surveyId = queryParams.get("surveyId");

    if (!selectedLangauge?.answer_code && !callback) {
      getLanguagesCallBack(surveyId, token);
      callback = true; // Add a state to track if the callback has been called
    }

    if (selectedLangauge?.answer_code) {
      getQuestionList(token, surveyId, selectedLangauge.answer_code);
    }
  }, [
    getQuestionList,
    selectedLangauge,
    isResponseSubmitted,
    getLanguagesCallBack,
    languagesCallbackCalled,
  ]);

  const onStartClick = (answer) => {
    if (answer.answer_code === "1") {
      setShouldWeStart(true);
      setStartSurvey({
        isSurveyStart: true,
        comment: answer?.answer,
      });
    }
    if (answer.answer_code === "2") {
      setShouldWeStart(false);
      setStartSurvey({
        isSurveyStart: true,
        comment: answer?.answer,
      });
      setIntialQuestions([{
        question: staticStatments?.[selectedLangauge.answer_code]?.NotNowResponse,}
      ]);
      //  window.location.href = "https://we-matter.com/"
    }
  };

  useEffect(() => {
    if (selectedLangauge?.answer_code) {
      setIntialQuestions(intialQuestion?.[selectedLangauge.answer_code]);
      // const intervalId = setInterval(() => {
      //   if (initialQIndex < intialQuestion?.[selectedLangauge.answer_code].length) {
      //     const newQuestion = intialQuestion?.[selectedLangauge.answer_code][initialQIndex];
      //     setIntialQuestions((prevQuestions) => [
      //       ...prevQuestions,
      //       newQuestion,
      //     ]);
      //     setInitialQIndex((prevIndex) => prevIndex + 1);
      //   } else {
      //     clearInterval(intervalId);
      //   }
      //   if (index === intialQuestion.length - 1) {
      //     setIsSurveyStart(true);
      //   }
      // }, 1000);
      if(!startSurvey.isSurveyStart){
        setTimeout(()=>{
          const editedQuestionElement = document.getElementById(
            `question-${intialQuestion?.[selectedLangauge.answer_code][intialQuestion?.[selectedLangauge.answer_code].length -1 ].qid}`
          );
    
          // Scroll the element into view
        if (editedQuestionElement) {
            editedQuestionElement.scrollIntoView({ behavior: "smooth" });
          }
        },1500)
      }
    
     

      return () => {
        // clearInterval(intervalId);
      };
    }
  }, [
    initialQIndex,
    setInitialQIndex,
    intialQuestion,
    setIntialQuestions,
    setAvlLangauges,
    setSelectedLanguage,
  ]);

  useEffect(() => {
    if (editIsOn && editedQuestionId?.isComment) {
      textareaRef.current.focus();
    }
  }, [editIsOn, showMessageList, getQuestionList]);

  const onEditClick = (qid, isComment, gid) => {
    setEditIsOn(true);
    if (isComment) {
      let question = showMessageList.find((quest) => {
        return quest.qid === qid;
      });
      setTextValue(question?.givenAnswer?.comment);
    }
    setEditedQuestionId({ qid: qid, isComment, gid });
  };

  const onSendClick = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const surveyId = queryParams.get("surveyId");
    if (!editIsOn) {
      if (
        textValue.length < showMessageList[showMessageList.length - 1].value
      ) {
        const replacedString = staticStatments?.[
          selectedLangauge.answer_code
        ].mandatoryTextField.replace(
          /\${number}/g,
          showMessageList[showMessageList.length - 1].value
        );
        alert(replacedString);
        return;
      }
    }

    let isLast = false;
    if (messagesList.length === 1 && !editIsOn) {
      isLast = true;
      // setTimeout(()=>{
      //   window.location.href="https://we-matter.com/"
      // },2000)
      setTimeout(() => {
        setisSurvyeSubmitted(true);
        setIntialQuestions([]);
        setDelayedQuestions([]);
        showMessageList = setShowMessagesList([
          {
            question:
              staticStatments?.[selectedLangauge.answer_code]?.thanksResponse,
          },
        ]);
      }, 1000);
    }
    if (editIsOn) {
      const quest = showMessageList.find((msg) => {
        return msg?.qid === editedQuestionId?.qid;
      });
      if (quest.type !== "comment" && quest.type !== "T") {
        const editedQuestionElement = document.getElementById(
          `question-${quest?.qid}`
        );

        // Scroll the element into view
        if (editedQuestionElement) {
          editedQuestionElement.scrollIntoView({ behavior: "smooth" });
        }
        alert(
          staticStatments?.[selectedLangauge.answer_code]?.alertOptionSelection
        );
        return;
      }
      if (textValue.length < quest.value) {
        const replacedString = staticStatments?.[
          selectedLangauge.answer_code
        ]?.mandatoryTextField.replace(/\${number}/g, quest.value);
        alert(replacedString);
        return;
      }
      const updatedList = showMessageList.map((msg) => {
        if (msg?.qid === editedQuestionId?.qid) {
          return {
            ...msg,
            givenAnswer: {
              ...msg.givenAnswer,
              comment: textValue,
            },
          };
        }
        return msg;
      });
      setUserInput(textValue);
      setTextValue("");
      setTextAreaRows(2);
      setEditIsOn(false);
      if (messagesList.length === 1 && !editIsOn) {
        isLast = true;
        // setTimeout(()=>{
        //   window.location.href="https://we-matter.com/"isSurvyeSubmitted
        // },2000)
        setTimeout(() => {
          setisSurvyeSubmitted(true);
          setIntialQuestions([]);
          setDelayedQuestions([]);
          showMessageList = setShowMessagesList([
            {
              question:
                staticStatments?.[selectedLangauge.answer_code]?.thanksResponse,
            },
          ]);
        }, 1000);
      }
      updateAnswer(
        { ...editedQuestionId, comment: textValue },
        token,
        surveyId,
        isLast
      );
      let failedResponse = await getAllFailedApiResponses();
      if (failedResponse.length > 0) {
        setIsOnline(false);
      }
      setUserInput("");
      setShowMessagesList([...updatedList]);
    } else {
      if (
        showMessageList[showMessageList.length - 1].type === "O" ||
        showMessageList[showMessageList.length - 1].type === "L"
      ) {
        return alert(
          staticStatments?.[selectedLangauge.answer_code]?.alertOptionSelection
        );
      }
      showMessageList[showMessageList.length - 1].givenAnswer = {
        comment: textValue,
      };
      if (
        showMessageList &&
        showMessageList[showMessageList.length - 1] &&
        showMessageList[showMessageList.length - 1].type &&
        showMessageList[showMessageList.length - 1].type
      ) {
        updateAnswer(
          {
            ...showMessageList[showMessageList.length - 1],
            comment: textValue,
            isComment: true,
          },
          token,
          surveyId,
          isLast
        );
        let failedResponse = await getAllFailedApiResponses();
        if (failedResponse.length > 0) {
          setIsOnline(false);
        }
      }
      const currentQuestion = messagesList.shift();
      // currentQuestion.givenAnswer.comment = textValue;
      setShowMessagesList([...showMessageList, currentQuestion]);
      setUserInput(textValue);
      setTextValue("");
      setTextAreaRows(2);
    }
  };

  async function onAnswerClick(answerObj, qid, gid, type, isEdit) {
    let isLast = false;
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const surveyId = queryParams.get("surveyId");
    const { aid, answer, answer_code } = answerObj;
    let answerObject = {
      aid,
      gid,
      answerCode: answer_code,
      qid,
      comment: isEdit ? textValue : userInput,
    };

    const messages = showMessageList.map((msg) => {
      if (msg?.qid === qid) {
        if (type === "L") {
          return { ...msg, givenAnswer: { answer_code, answer: answer } };
        }
        if (msg?.givenAnswer?.comment && msg?.givenAnswer?.comment === null) {
          return {
            ...msg,
            givenAnswer: { answer_code: { answer_code, answer } },
          };
        }
        return {
          ...msg,
          givenAnswer: {
            answer_code: { answer_code, answer, comment: textValue },
          },
        };
      }
      return msg;
    });
    if (messagesList.length === 1 && !editIsOn) {
      setShowMessagesList([...messages, messagesList.shift()]);
      isLast = true;
      // setTimeout(()=>{
      //  window.location.href="https://we-matter.com/"
      //  },2000)
      setTimeout(() => {
        setisSurvyeSubmitted(true);
        setIntialQuestions([]);
        setDelayedQuestions([]);
        showMessageList = setShowMessagesList([
          {
            question:
              staticStatments?.[selectedLangauge.answer_code]?.thanksResponse,
          },
        ]);
      }, 1000);
    }
    if (type && type === "L") {
      delete answerObj?.comment;
      delete answerObject.comment;
    }
    updateAnswer(answerObject, token, surveyId, isLast).then(console.log); //.catch(alert);
    let failedResponse = await getAllFailedApiResponses();
    if (failedResponse.length > 0) {
      setIsOnline(false);
    }
    if (type && type === "O") {
      setUserInput(null);
      setTextValue("");
    }
    if (
      !isEdit &&
      answerObject?.qid === showMessageList[showMessageList.length - 1]?.qid &&
      messages[messages.length - 1]?.givenAnswer?.answer_code !== null &&
      !isEdit &&
      !messagesList[messagesList.length - 1].type !== "O" &&
      !messagesList[messagesList.length - 1].type !== "T"
    ) {
      if (type === "L") {
        let question = messages.find((msg) => {
          return msg.qid === qid;
        });
        let nextQuestion = messagesList.shift();
        if (!nextQuestion) {
          return;
        }
        if (nextQuestion && nextQuestion?.title?.startsWith(question?.title)) {
          if (answer_code <= 3) {
            setShowMessagesList([...messages, nextQuestion]);
          } else {
            setShowMessagesList([...messages, messagesList.shift()]);
          }
        } else {
          setShowMessagesList([...messages, nextQuestion]);
        }
      } else {
        setShowMessagesList([...messages, messagesList.shift()]);
      }
      setEditIsOn(false);
    } else {
      setShowMessagesList([...messages]);
      setEditIsOn(false);
    }
  }

  useEffect(() => {
    if (!customLTypeQuestion) {
      const intervalId = setInterval(() => {
        if (index < followupQuestions.length && startSurvey.isSurveyStart) {
          const newQuestion = followupQuestions[index];
          setDelayedQuestions((prevQuestions) => [
            ...prevQuestions,
            newQuestion,
          ]);
          setIndex((prevIndex) => prevIndex + 1);
        } else {
          clearInterval(intervalId);
        }
        if (index === followupQuestions.length - 1) {
          setIsSurveyStart(true);
        }
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    } else {
      setIsSurveyStart(true);
    }
  }, [index, followupQuestions, startSurvey]);
  // Logging delayedQuestions to the console
  useEffect(() => {}, [delayedQuestions]);
  const disableZoom = () => {
    const viewport = document.querySelector('meta[name="viewport"]');
    if (viewport) {
      viewport.content =
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
    }
  };

  const enableZoom = () => {
    const viewport = document.querySelector('meta[name="viewport"]');
    if (viewport) {
      viewport.content =
        "width=device-width, initial-scale=1.0, maximum-scale=5.0";
    }
  };

  const handleFocus = () => {
    disableZoom();
  };

  const handleBlur = () => {
    enableZoom();
  };
  return isOnline ? (
    <>
      <ProgressBar completed={total - messagesList.length} total={total} />
      <Dropdown
        setSelectedLanguage={setSelectedLanguage}
        selectedLangauge={selectedLangauge}
        options={avlLangauges}
      ></Dropdown>
      <div className="card mobile:pl-2 mobile:pt-2 mobile:pr-2 mobile:pb-14 md:p-5 lg:p-7 bg-white">
        <div className="chat-thread">
          {isLinkInValid ? (
            <QuestionAnswer
              predfinedQuestionsForComment={{
                question:
                  staticStatments?.[selectedLangauge.answer_code]
                    ?.predfinedQuestionsForComment,
              }}
              questionObject={{ question: invalidLinkMessage }}
            />
          ) : null}
          {isResponseSubmitted ? (
            <>
              <QuestionAnswer
                predfinedQuestionsForComment={{
                  question:
                    staticStatments?.[selectedLangauge.answer_code]
                      ?.predfinedQuestionsForComment,
                }}
                questionObject={{
                  question:
                    staticStatments?.[selectedLangauge.answer_code]
                      ?.surveySubmitAlready,
                }}
              />
            </>
          ) : null}

          {!isSurvyeSubmitted &&
            !isResponseSubmitted &&
            !isLinkInValid &&
            !selectedLangauge?.answer_code &&
            avlLangauges?.length > 1 &&
            LanguageSelection.map((question, index) => (
              <QuestionAnswer
                predfinedQuestionsForComment={{
                  question:
                    staticStatments?.[selectedLangauge.answer_code]
                      ?.predfinedQuestionsForComment,
                }}
                showMessageList={showMessageList}
                questionObject={question}
                key={index}
                answerSelectionCallback={onLanguageSelection}
              />
            ))}
          {!isSurvyeSubmitted &&
            !isResponseSubmitted &&
            !isLinkInValid &&
            selectedLangauge?.answer_code &&
            avlLangauges?.length > 1 &&
            LanguageSelection.map((question, index) => (
              <QuestionAnswer
                predfinedQuestionsForComment={{
                  question:
                    staticStatments?.[selectedLangauge.answer_code]
                      ?.predfinedQuestionsForComment,
                }}
                showMessageList={showMessageList}
                questionObject={question}
                key={index}
                answerSelectionCallback={onLanguageSelection}
              />
            ))}
          {!isResponseSubmitted &&
            selectedLangauge?.answer_code &&
            !isLinkInValid &&
            intialQuestions.map((question, index) => (
              <QuestionAnswer
                predfinedQuestionsForComment={{
                  question:
                    staticStatments?.[selectedLangauge.answer_code]
                      ?.predfinedQuestionsForComment,
                }}
                showMessageList={showMessageList}
                startSurvey={startSurvey}
                questionObject={question}
                key={question.qid}
                answerSelectionCallback={
                  index === intialQuestions.length - 1 &&
                  !startSurvey.isSurveyStart
                    ? onStartClick
                    : onLanguageSelection
                }
              />
            ))}
          {!isResponseSubmitted &&
          shouldWeStart &&
          !isLinkInValid &&
          startSurvey.isSurveyStart
            ? delayedQuestions.map((question, index) => (
                <QuestionAnswer
                  predfinedQuestionsForComment={{
                    question:
                      staticStatments?.[selectedLangauge.answer_code]
                        ?.predfinedQuestionsForComment,
                  }}
                  showMessageList={showMessageList}
                  startSurvey={startSurvey}
                  questionObject={question}
                  key={index}
                  answerSelectionCallback={
                    index === delayedQuestions.length - 1 &&
                    !startSurvey.isSurveyStart
                      ? onStartClick
                      : null
                  }
                />
              ))
            : null}
          {!isResponseSubmitted &&
          shouldWeStart &&
          !isLinkInValid &&
          startSurvey.isSurveyStart &&
          isSurveyStart
            ? showMessageList?.map(function (question) {
                return (
                  <QuestionAnswer
                    predfinedQuestionsForComment={{
                      question:
                        staticStatments?.[selectedLangauge.answer_code]
                          ?.predfinedQuestionsForComment,
                    }}
                    customLTypeQuestion={customLTypeQuestion}
                    key={question?.qid}
                    setShowMessagesList={setShowMessagesList}
                    showMessageList={showMessageList}
                    onEditClick={onEditClick}
                    editedQuestionId={editedQuestionId}
                    setEditedQuestionId={setEditedQuestionId}
                    userInput={userInput}
                    editIsOn={editIsOn}
                    setEditIsOn={setEditIsOn}
                    setTextValue={setTextValue}
                    answerSelectionCallback={onAnswerClick}
                    questionObject={question}
                  ></QuestionAnswer>
                );
              })
            : null}
        </div>
        <div class="textareacss">
          <textarea
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleTextareaInput}
            ref={textareaRef}
            placeholder={
              showMessageList[showMessageList?.length - 1]?.type === "L"
                ? ""
                : "Type your comment here...."
            }
            disabled={
              showMessageList?.[0]?.question?.includes(
                "Thank you for the response"
              ) || showMessageList?.length === 0
                ? true
                : showMessageList[showMessageList?.length - 1]?.type === "L"
                ? true
                : false
            }
            maxRows={5}
            type="text"
            value={textValue}
            name="commentresponse"
            id="bottom"
            className="commentbox text-base mobile:text-xs font-medium pl-5 pt-5 pr-14 mobile:pr-12 mobile:pl-3.5 mobile:pt-3.5"
            spellcheck="false"
          />

          <img
            alt="send button"
            onClick={onSendClick}
            className="sendIcon"
            src={sendIcon}
          />
        </div>
      </div>
    </>
  ) : (
    <OfflineMessage></OfflineMessage>
  );
}
