import axios from "axios";
import { APIURLS } from "../config/apiUrls";

const baseApiUrl =
   process.env.REACT_APP_API_URL || "https://kyara-api.we-matter.com";
//  process.env.REACT_APP_API_URL || "kyara-api.we-matter.com";

export const getQuestions = async (token, surveyId, language, relation) => {
  const { data } = await axios({
    method: "GET",
    url: baseApiUrl + APIURLS.questions,
    params: {
      surveyId: surveyId,
      language: `'${language}'`,
      token: token,
      relation,
    },
  });
  return data;
};

export const getLogoUrl = async (surveyId) => {
  const logo = await axios({
    method: "GET",
    url: baseApiUrl + APIURLS.url,
    params: {
      surveyId: surveyId,
    },
  });
  return logo.data;
};
export const getSurveyStatments = async (surveyId, language) => {
  const { data } = await axios({
    method: "GET",
    url: baseApiUrl + APIURLS.statments,
    params: {
      language,
      surveyId,
    },
  });
  return data;
};
export const getLanguages = async (surveyId) => {
  const { data } = await axios({
    method: "GET",
    url: baseApiUrl + APIURLS.languages,
    params: {
      surveyId: surveyId,
    },
  });
  return data;
};

export const createSurvey = async (token, surveyId, language) => {
  const { data } = await axios({
    method: "POST",
    url: baseApiUrl + APIURLS.createSurvey,
    params: {
      surveyId: surveyId,
      token: token,
      language,
    },
  });
  return data;
};

export const getInitialQuestion = async (language, surveyId, relation) => {
  const { data } = await axios({
    method: "GET",
    url: baseApiUrl + APIURLS.initialQuestion,
    params: {
      language,
      surveyId,
      relation,
    },
  });
  return data;
};

export const getFollowupQuestion = async (language, surveyId) => {
  const { data } = await axios({
    method: "GET",
    url: baseApiUrl + APIURLS.followUpQuestion,
    params: {
      language,
      surveyId,
    },
  });
  return data;
};

export const updateAnswer = async (answerObject, token, surveyId, isLast) => {
  try {
    const { data } = await axios({
      method: "PUT",
      url: baseApiUrl + APIURLS.answer,
      params: {
        surveyId: surveyId,
        token: token,
      },
      data: { answerObject: { ...answerObject, isLast } },
    });

    // Successful API request, clear any stored data for this specific response
    clearFailedApiResponse(answerObject);

    return data;
  } catch (error) {
    // If API request fails, store response in local storage for later retry
    storeFailedApiResponse(answerObject, token, surveyId, isLast);

    console.error(
      "API request failed. Response stored in local storage for later retry.",
      error
    );

    // Retry after 3 seconds using a recursive call
    await new Promise((resolve) => setTimeout(resolve, 3000));
    return updateAnswer(answerObject, token, surveyId, isLast);
  }
};

// Helper function to store a failed response in local storage
const storeFailedApiResponse = (answerObject, token, surveyId, isLast) => {
  const storedResponses = getAllFailedApiResponses();
  storedResponses.push({ answerObject, token, surveyId, isLast });
  localStorage.setItem("failedApiResponses", JSON.stringify(storedResponses));
};

// Helper function to retrieve all failed responses from local storage
export const getAllFailedApiResponses = () => {
  const storedResponses = localStorage.getItem("failedApiResponses");
  return storedResponses ? JSON.parse(storedResponses) : [];
};

// Helper function to clear a specific failed response from local storage
const clearFailedApiResponse = (answerObject) => {
  const storedResponses = getAllFailedApiResponses();
  const updatedResponses = storedResponses.filter(
    (response) => !isEqual(response.answerObject, answerObject)
  );
  localStorage.setItem("failedApiResponses", JSON.stringify(updatedResponses));
};

// Helper function to check if two objects are equal
const isEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};
