import React from "react";
import "./Question.css";
// import kyraImage from "../../assets/kyaramalerepl.png";
import kyraImage from "../../assets/kface1.png";
import "normalize.css";

export default function Question(props) {
  const { question } = props;

  return (
    <>
      {question ? (
        <div className="message">
          <div className="avatar">
            {
              // eslint-disable-next-line
              <img className="profileImage" src={kyraImage}></img>
            }
          </div>
          <div className="message-content py-4 px-6 mobile:py-3.5 mobile:px-4">
            <div className="typing-indicator">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              className="text-base mobile:text-xs font-medium"
              style={{ zIndex: 800 }}
            >
              {question}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
